// NOT IN USE ANYMORE
// Main control is now on dataGridControl.rowDrag, NodeData related functions are on dataGridControl.nodeData.rowDrag.
// Config is provided from the main control
import type { DataItemModel } from 'o365-dataobject';

import { NodeItem } from './DataObject.NodeItem.ts';
import { addEventListener } from 'o365-vue-utils';
import { DataGridControl } from 'o365-datagrid';
import { logger } from 'o365-utils';

// declare module 'o365-datagrid' {
//     interface DataGridControl {
//         rowDrag: DataGridRowDrag;
//         hasRowDrag: boolean;
//     }
// }

Object.defineProperties(DataGridControl.prototype, {
    'rowDrag2': {
        get() {
            if (this._rowDrag == null) {
                this._rowDrag = new DataGridRowDrag(this);
            }
            return this._rowDrag;
        }
    },
    'hasRowDrag2': {
        get() {
            return !!this._rowDrag && this._rowDrag.isNodeDrag;
        }
    }
});

export default class DataGridRowDrag {
    static get TransferType() { return 'o365-nt/data-grid-row-drag'; }

    private _dataGridControl: DataGridControl;

    private _cleanupTokens: (() => void)[] = [];

    private _dragImage?: HTMLElement;
    private _dragIndicator?: HTMLElement;

    get step() { return 10000; }
    get direction() { return true ? 'asc' : 'desc'; }
    get orderField() { return 'SortOrder'; }
    get isNodeDrag() { return true; }

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    enable() {
        this._cleanupTokens.splice(0, this._cleanupTokens.length).forEach(ct => ct());
        this._dataGridControl.hasRowDrag = true;
        if (this._dataGridControl.container) {
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragstart', this._onDragStart.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragover', this._onDragOver.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragleave', this._onDragLeave.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'drop', this._onDrop.bind(this)));
        }

        this._cleanupTokens.push(this._dataGridControl.dataObject!.nodeData.events.on('AfterIndent', (pNode) => {
            const scope = pNode.getParent()?.details ?? this._dataGridControl.dataObject!.nodeData.root;
            const indexInScope = scope.findIndex(x => x.key === pNode.key);
            if (indexInScope === -1) { return; }

            const rowAbove = scope[indexInScope - 1];
            const rowBelow = scope[indexInScope + 1];
            this.updateSortOrder(pNode, rowAbove as any, rowBelow as any);
        }));

        this._cleanupTokens.push(this._dataGridControl.dataObject!.nodeData.events.on('AfterOutdent', (pNode) => {
            const scope = pNode.getParent()?.details ?? this._dataGridControl.dataObject!.nodeData.root;
            const indexInScope = scope.findIndex(x => x.key === pNode.key);
            if (indexInScope === -1) { return; }

            const rowAbove = scope[indexInScope - 1];
            const rowBelow = scope[indexInScope + 1];
            this.updateSortOrder(pNode, rowAbove as any, rowBelow as any);
        }));

        this._cleanupTokens.push(this._dataGridControl.dataObject!.nodeData.events.on('NodeAdded', (pNode) => {
            const scope = pNode.getParent()?.details ?? this._dataGridControl.dataObject!.nodeData.root;
            const indexInScope = scope.findIndex(x => x.key === pNode.key);
            if (indexInScope === -1) { return; }

            const rowAbove = scope[indexInScope - 1];
            const rowBelow = scope[indexInScope + 1];

            this.updateSortOrder(pNode, rowAbove as any, rowBelow as any);
        }));

    }

    dispose() {
        this._cleanupTokens.forEach(ct => ct());
        (this._dataGridControl as any)._rowDrag = null;
    }

    updateSortOrder(pRow: NodeItem, pAboveRow?: NodeItem, pBelowRow?: NodeItem) {
        if (pAboveRow == null && pBelowRow == null) { return; }

        let newSortOrder = 0;
        if (pAboveRow == null) {
            const below = this._datetimeToNumber(pBelowRow!.SortOrder);
            if (this.direction === 'asc') {
                newSortOrder = below - this.step;
            } else {
                newSortOrder = below + this.step;
            }
        } else if (pBelowRow == null) {
            const above = this._datetimeToNumber(pAboveRow!.SortOrder);
            if (this.direction === 'asc') {
                newSortOrder = above + this.step;
            } else {
                newSortOrder = above - this.step;
            }
        }
        else if (pAboveRow!.parentKey == pBelowRow!.parentKey) {
            // row above and below are in the same scope
            const above = this._datetimeToNumber(pAboveRow.SortOrder);
            const below = this._datetimeToNumber(pBelowRow.SortOrder);
            if (above === below) {
                // TODO: Find new closest sort order and update all equal ones 
                newSortOrder = (above + below) / 2;
            } else {
                newSortOrder = (above + below) / 2;
            }
        } else if (pAboveRow.key == pBelowRow.parentKey) {
            // row above is the parent of the below row
            const below = this._datetimeToNumber(pBelowRow.SortOrder);
            if (this.direction === 'asc') {
                newSortOrder = below - this.step;
            } else {
                newSortOrder = below + this.step;
            }
        } else {
            // below row is on a diffrent scope, dont use it
            const above = this._datetimeToNumber(pAboveRow.SortOrder);
            if (this.direction === 'asc') {
                newSortOrder = above + this.step;
            } else {
                newSortOrder = above - this.step;
            }
        }
        pRow.dataItem![this.orderField] = new Date(newSortOrder);
    }

    updateParentBinding(pRow: NodeItem, pAboveRow?: NodeItem, pBelowRow?: NodeItem) {
        if (pAboveRow == null && pBelowRow == null) { return; }

        let parent: NodeItem | undefined = undefined;
        let parentId: any = null;
        let searchForAbove = true;
        if (pAboveRow == null) {
            searchForAbove = false;
        } else if (pBelowRow == null) {
            parent = pAboveRow.getParent();
            parentId = pAboveRow.parentId;
        }
        else if (pAboveRow.parentKey == pBelowRow.parentKey) {
            // row above and below are in the same scope
            parent = (pAboveRow as any as NodeItem)?.getParent();
            parentId = pAboveRow.parentId;
        } else if (pAboveRow.key == pBelowRow.parentKey) {
            // row above is the parent of the below row
            searchForAbove = false;
            parent = pBelowRow?.getParent();
            parentId = pBelowRow?.parentId;
        } else {
            // below row is on a diffrent scope, dont use it
            parent = pAboveRow?.getParent();
            parentId = pAboveRow?.parentId;
        }

        const scope = parent?.details ?? this._dataGridControl.dataObject!.nodeData.root;
        if (searchForAbove) {
            const indexToInsert = scope.findIndex(x => x.key === pAboveRow!.key);
            scope.splice(indexToInsert + 1, 0, pRow);
        } else {
            const indexToInsert = scope.findIndex(x => x.key === pBelowRow!.key);
            scope.splice(indexToInsert, 0, pRow);
        }

        pRow.getParent = () => parent;
        const config = pRow.getConfiguration();
        config.updateNodeParent(pRow, parent, parentId);
    }

    private _onDragStart(pEvent: DragEvent) {
        if (pEvent.dataTransfer == null) { return; }
        const rowHandle = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-rowhandle');
        if (rowHandle == null) { return; }

        const row = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-body-row');
        if (row == null) { return; }
        const rowIndex = +row.dataset.o365Rowindex!;
        const rowKey = rowHandle.dataset.o365DragKey!;


        pEvent.dataTransfer.effectAllowed = 'move';
        pEvent.dataTransfer.setData(DataGridRowDrag.TransferType, JSON.stringify({ index: +rowIndex, key: rowKey } as RowDragData));

        const dragImage = this._getDragImage((pEvent.target as HTMLElement));
        pEvent.dataTransfer.setDragImage(dragImage, 0, -15);
        this._dataGridControl.navigation.clearSelection();
        this._dataGridControl.navigation.clearFocus();
    }

    private _onDragOver(pEvent: DragEvent) {
        const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
        if (!isDragItem) { return; }
        if (pEvent.dataTransfer == null) { return; }
        const [row, rowEl, _index, bottomEdge] = this._getRowFromEvent(pEvent);
        if (row == null || rowEl == null) {
            pEvent.dataTransfer.dropEffect = 'none';
            this._clearDragIndicator();
            return;
        }

        this._updateDragIndicator(rowEl, bottomEdge!);
        pEvent.dataTransfer.dropEffect = 'move';
        pEvent.preventDefault();
    }

    private _onDragLeave(pEvent: DragEvent) {
        const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
        if (pEvent.dataTransfer == null) { return; }
        if (isDragItem) {
            const closest = (pEvent.target as HTMLElement)?.closest('.o365-body-row');
            if (closest == null) {
                pEvent.dataTransfer.dropEffect = 'none';
            }
        }
    }

    private _onDrop(pEvent: DragEvent) {
        try {
            const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
            if (pEvent.dataTransfer == null) { return; }
            if (!isDragItem) { return; }
            const [row, rowEl, index, bottomEdge] = this._getRowFromEvent(pEvent);
            if (row == null || rowEl == null) {
                return;
            }

            const json = pEvent.dataTransfer.getData(DataGridRowDrag.TransferType);
            const dragOptions: RowDragData = JSON.parse(json);
            const draggedRow: NodeItem = this._dataGridControl.dataObject?.data[dragOptions.index] as any;
            if (draggedRow == null || row.index == draggedRow.index) { return; }


            let rowAbove: NodeItem | undefined = undefined;
            let rowBelow: NodeItem | undefined = undefined;
            if (bottomEdge) {
                // position between row and row below
                rowAbove = row as any;
                rowBelow = this._dataGridControl.dataObject!.data[index! + 1] as any;
            } else {
                // position between row and row above
                rowAbove = this._dataGridControl.dataObject!.data[index! - 1] as any;
                rowBelow = row as any;
            }
            if (draggedRow.key === rowAbove?.key || draggedRow.key === rowBelow?.key) { return;}

            // Remove dragged row from its parent scope
            const currentParent = draggedRow.getParent();
            let indexInCurrentParent = -1;
            if (currentParent) {
                indexInCurrentParent = currentParent.details.findIndex(x => x.key === draggedRow.key);
            } else {
                indexInCurrentParent = this._dataGridControl.dataObject!.nodeData.root.findIndex(x => x.key === draggedRow.key);
            }
            if (indexInCurrentParent !== -1) {
                if (currentParent) {
                    currentParent.details.splice(indexInCurrentParent, 1);
                } else {
                    this._dataGridControl.dataObject!.nodeData.root.splice(indexInCurrentParent, 1);
                }
            }

            this.updateSortOrder(draggedRow, rowAbove, rowBelow);
            this.updateParentBinding(draggedRow, rowAbove, rowBelow);

            draggedRow.save();
            this._dataGridControl.dataObject!.nodeData.update();
        } catch (ex) {
            logger.error(ex);
        } finally {
            this._clearDragImage();
            this._clearDragIndicator();
        }
    }

    private _getDragImage(pElement: HTMLElement) {
        if (this._dragImage) { this._dragImage.remove(); }
        this._clearDragImage();
        const rowContainer = document.createElement('div');
        rowContainer.style.width = pElement.closest<HTMLElement>('.o365-body-center-viewport')!.clientWidth + 'px';
        rowContainer.className = 'o365-data-grid';
        rowContainer.style.height = '34px';
        const rowL = pElement.closest<HTMLElement>('.o365-body-row')!.cloneNode(true) as HTMLElement;
        this._cleanDragRowStyles(rowL, 'left');
        const rowIndex = +rowL.dataset.o365Rowindex!;
        let rowC = this._dataGridControl.container!.querySelector<HTMLElement>(`.o365-body-center-cols [data-o365-rowindex="${rowIndex}"]`)
        rowContainer.append(rowL);
        if (rowC) {
            rowC = rowC.cloneNode(true) as HTMLElement;
            this._cleanDragRowStyles(rowC);
            rowContainer.append(rowC);
        }
        let rowR = pElement.closest<HTMLElement>('.o365-body-right-pinned-cols [data-o365-rowindex="${rowIndex}"]');
        if (rowR) {
            rowR = rowR.cloneNode(true) as HTMLElement;
            this._cleanDragRowStyles(rowR, 'right');
            rowContainer.append(rowR);
        }
        this._dragImage = rowContainer;
        this._dragImage.style.zIndex = '2000';
        this._dragImage.style.position = 'absolute';
        this._dragImage.style.left = '-9999px';
        this._dragImage.style.top = '-9999px';
        document.body.append(this._dragImage);
        return this._dragImage;
    }

    private _cleanDragRowStyles(pRow: HTMLElement, pPin?: 'left' | 'right') {
        pRow.style.transform = '';
        pRow.className = 'o365-body-row bg-body'
        switch (pPin) {
            case 'left':
                // pRow.style.width = this._dataGridControl.dataColumns.leftPinnedWidth + 'px';
                break;
            case 'right':
                // pRow.style.width = this._dataGridControl.dataColumns.rightPinnedWidth + 'px';
                pRow.style.right = '0px';
                break;
            default:
                // pRow.style.width = this._dataGridControl.dataColumns.centerWidth + 'px';
                pRow.style.left = this._dataGridControl.dataColumns.leftPinnedWidth + 'px';
                break;
        }
        pRow.querySelectorAll<HTMLElement>('.o365-body-cell').forEach(cell => {
            cell.className = 'o365-body-cell';
        });
    }

    private _clearDragImage() {
        if (this._dragImage) { this._dragImage.remove(); }
        this._dragImage = undefined;
    }

    private _clearDragIndicator() {
        if (this._dragIndicator) { this._dragIndicator.remove(); }
        this._dragIndicator = undefined;
    }

    private _updateDragIndicator(pRowEl: HTMLElement, pBelow?: boolean) {
        const container = this._dataGridControl.container?.querySelector('.o365-body-center-viewport')
        if (container == null) { this._clearDragIndicator(); return; }
        if (this._dragIndicator == null) {
            this._dragIndicator = document.createElement('div');
            this._dragIndicator.className = 'o365-drag-indicator';
            container.append(this._dragIndicator);
        }
        if (pBelow) {
            const str = pRowEl.style.transform as string;
            const translate = parseInt((str.match(/\d+/) || [])[0]); parseInt(pRowEl.style.transform);
            this._dragIndicator.style.transform = `translateY(${translate + pRowEl.clientHeight}px)`;

        } else {
            this._dragIndicator.style.transform = pRowEl.style.transform;
        }
    }

    private _getRowFromEvent(pEvent: DragEvent): [DataItemModel?, HTMLElement?, number?, boolean?] {
        const row = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-body-row');
        if (row == null) { return []; }
        const index = +row.dataset.o365Rowindex!;

        const rect = row.getBoundingClientRect();
        const midpoint = rect.top + (rect.height / 2);
        const bottomEdge = pEvent.clientY > midpoint;

        return [this._dataGridControl.dataObject!.data[index], row, index, bottomEdge];
    }

    private _datetimeToNumber(pDate: string | number | Date) {
        if (pDate instanceof Date) {
            return +pDate;
        } else if (typeof pDate === 'string') {
            return + new Date(pDate);
        } else {
            return pDate;
        }
    }

}

export type RowDragData = {
    index: number,
    key: string,
};